import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Layouts/Navbar";
import About from "./components/About";
import Contact from "./components/Contact";
import Notfound from "./components/Notfound";
import Profile from "./components/Profile";
import Footer from "./components/Layouts/Footer";
import { useEffect } from "react";
import "aos/dist/aos.css";
import Aos from "aos";

function App() {
  useEffect(() => {
    Aos.init({
      once: true,
    });
  });

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Profile />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
