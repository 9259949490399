import React from "react";
import { Link, useLocation } from "react-router-dom";

function Navbar() {
  const uri = useLocation().pathname;

  return (
    <nav className="navbar">
      {/* <ul>
        <li className=>
          <Link to="/">Profil</Link>
        </li>
        <li className=>
          <Link to="/about">Tentang</Link>
        </li>
        <li className={uri === "/contact" ? "active" : ""}>
          <Link to="/contact">Kontak</Link>
        </li>
      </ul> */}
      <div className="nav-links">
        <Link to="/" className={uri === "/" ? "active" : ""}>Profil</Link>
        <Link to="/about" className={uri === "/about" ? "active" : ""}>Tentang</Link>
        <Link to="/contact" className={uri === "/contact" ? "active" : ""}>Kontak</Link>
      </div>
    </nav>
  );
}

export default Navbar;
