import React from "react";

function Contact() {
  return (
    <div className="contact">
      <h1 data-aos="zoom-in" data-aos-duration="1000">
        Kontak Saya
      </h1>

      <div data-aos="zoom-in" data-aos-duration="1500" className="wrap-contact">
        <div className="wrap-table">
          <label>Data Kontak</label>
          <div className="table-responsive">
            <table>
              <tr>
                <td>No Hp</td>
                <td>:</td>
                <td>081360798087</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>muhammadriskifauzi10@gmail.com</td>
              </tr>
              <tr>
                <td>Facebook</td>
                <td>:</td>
                <td>
                  <a
                    href="https://www.facebook.com/profile.php?id=100072591907048"
                    className="follow"
                    target="_blank"
                  >
                    + Ikuti
                  </a>
                </td>
              </tr>
              <tr>
                <td>Instagram</td>
                <td>:</td>
                <td>
                  <a
                    href="https://instagram.com/muhammadriskifauzi10?igshid=OGQ5ZDc2ODk2ZA=="
                    className="follow"
                    target="_blank"
                  >
                    + Ikuti
                  </a>
                </td>
              </tr>
              <tr>
                <td>Linkedin</td>
                <td>:</td>
                <td>
                  <a
                    href="https://www.linkedin.com/in/muhammad-riski-fauzi-937168259/"
                    className="follow"
                    target="_blank"
                  >
                    + Ikuti
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
