import React from "react";

function About() {
  return (
    <div className="about">
      <h1 data-aos="zoom-in" data-aos-duration="1000">
        Tentang Saya
      </h1>
      {/* Data Pribadi */}
      <div
        data-aos="fade-right"
        data-aos-duration="1500"
        className="wrap-about"
      >
        <div className="wrap-table">
          <label>Data Pribadi</label>
          <div className="table-responsive">
            <table>
              <tr>
                <td>Nama</td>
                <td>:</td>
                <td>Muhammad Riski Fauzi, S.Kom</td>
              </tr>
                {/* <tr>
                  <td>Usia</td>
                  <td>:</td>
                  <td>23 Tahun</td>
                </tr> */}
              <tr>
                <td>Agama</td>
                <td>:</td>
                <td>Islam</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>muhammadriskifauzi10@gmail.com</td>
              </tr>
              <tr>
                <td>No Hp</td>
                <td>:</td>
                <td>081360798087</td>
              </tr>
              <tr>
                <td>Pendidikan</td>
                <td>:</td>
                <td>S1 Sarjana Komputer</td>
              </tr>
              <tr>
                <td>Pekerjaan</td>
                <td>:</td>
                <td>IT Web Programming</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      {/* Data Pendidikan */}
      <div data-aos="fade-left" data-aos-duration="1500" className="wrap-about">
        <div className="wrap-table">
          <label>Data Pendidikan</label>
          <div className="table-responsive">
            <table>
              <tr>
                <td>Nama Instansi</td>
                <td>:</td>
                <td>STMIK Triguna Dharma Medan</td>
              </tr>
              <tr>
                <td>Jurusan</td>
                <td>:</td>
                <td>Sistem Komputer</td>
              </tr>
              <tr>
                <td>IPK</td>
                <td>:</td>
                <td>3.30</td>
              </tr>
              <tr>
                <td>Keterangan</td>
                <td>:</td>
                <td>Lulus</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      {/* Data Pengalaman Kerja */}
      <div
        data-aos="fade-right"
        data-aos-duration="1500"
        className="wrap-about"
      >
        <div className="wrap-table">
          <label>Data Pengalaman Kerja</label>
          <div className="table-responsive">
            <table>
              <tr>
                <td>Nama Perusahaan</td>
                <td>:</td>
                <td>PT. Indone Kargo Ekspress</td>
              </tr>
              <tr>
                <td>Industri</td>
                <td>:</td>
                <td>Logistik</td>
              </tr>
              <tr>
                <td>Dari Tahun</td>
                <td>:</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>Sd Tahun</td>
                <td>:</td>
                <td>2022</td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td>:</td>
                <td>IT Web Programming</td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td>Nama Perusahaan</td>
                <td>:</td>
                <td>Katsudoto</td>
              </tr>
              <tr>
                <td>Industri</td>
                <td>:</td>
                <td>Wedding Invitation</td>
              </tr>
              <tr>
                <td>Dari Tahun</td>
                <td>:</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>Sd Tahun</td>
                <td>:</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td>:</td>
                <td>IT Web Programming</td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td>Nama Perusahaan</td>
                <td>:</td>
                <td>PTPN 4 <strong className="primary">(BUMN)</strong></td>
              </tr>
              <tr>
                <td>Industri</td>
                <td>:</td>
                <td>Perkebunan</td>
              </tr>
              <tr>
                <td>Dari Tahun</td>
                <td>:</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>Sd Tahun</td>
                <td>:</td>
                <td>2023</td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td>:</td>
                <td>IT Web Programming (Magang)</td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td colSpan="3"></td>
              </tr>
              <tr>
                <td>Nama Perusahaan</td>
                <td>:</td>
                <td>RSU Bunda Thamrin</td>
              </tr>
              <tr>
                <td>Industri</td>
                <td>:</td>
                <td>Kesehatan</td>
              </tr>
              <tr>
                <td>Dari Tahun</td>
                <td>:</td>
                <td className="primary">2023 - Sekarang</td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td>:</td>
                <td>IT Web Programming</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
