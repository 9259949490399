import React from "react";

function Profile() {
  return (
    <div className="profile">
      {/* <div data-aos="zoom-in" data-aos-duration="500" className="wrap-img">
        <img src="/myprofile.jpg" className="img-profile" />
      </div> */}

      <h1 data-aos="zoom-in" data-aos-duration="1000" className="name">
        Muhammad Riski Fauzi, S.Kom
      </h1>

      <div  data-aos="zoom-in" data-aos-duration="1500" className="wrap-description">
        <p>
          Sebagai seorang yang ahli dalam web programming, saya memiliki
          keahlian dalam pengembangan front-end dan back-end. Memiliki pemahaman
          mendalam tentang HTML, CSS, dan JavaScript untuk menciptakan antarmuka
          pengguna yang responsif dan menarik. Keahlian saya juga meliputi
          penggunaan framework seperti ReactJS untuk pengembangan front-end dan
          Laravel untuk membangun aplikasi server-side. Saya memiliki pengalaman
          dalam mengintegrasikan API dan bekerja dengan database seperti MySQL.
          Kemampuan saya dalam menangani proyek-proyek dari konsep hingga
          peluncuran membuat saya efektif dalam menghadapi tantangan di dunia
          web programming.
        </p>
      </div>
    </div>
  );
}

export default Profile;
