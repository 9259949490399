import React from "react";

function Footer() {
  return (
    <footer className="footer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f6f9e8"
          fillOpacity="1"
          d="M0,96L48,122.7C96,149,192,203,288,197.3C384,192,480,128,576,90.7C672,53,768,43,864,74.7C960,107,1056,181,1152,186.7C1248,192,1344,128,1392,96L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>
    </footer>
  );
}

export default Footer;
